import React, { useContext } from "react";

import GlobalContext from "../../context/GlobalContext";
import imgC from "../../assets/image/hero/diy.jpeg";

const CTA  = ({ className, ...rest }) => {
  const gContext = useContext(GlobalContext);

  return (
    <div className={className} {...rest} style={{position: "relative", top:"-2px", zIndex:"100"}}>
      <div className="pt-14 pt-md-19 pt-lg-10 pb-15 pb-md-18 pb-lg-25 mb-lg-1">
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div
            className="col-xl-6 col-lg-6 col-md-10 col-sm-11"
            data-aos="fade-right"
            data-aos-duration={500}
          >
            <div
              className="bg-images d-flex align-items-center justify-content-center max-w-540 py-18 py-sm-28 rounded-10 mx-auto"
              css={`
                background-image: url(${imgC});
              `}
            >
              <a
                href="/#"
                className="video-btn sonar bg-blue-3-op5 text-white circle-98 font-size-8"
                onClick={(e) => {
                  e.preventDefault();
                  gContext.toggleVideoModal();
                }}
                style={{visibility:"hidden"}}
              >
                <i className="fa fa-play" />
              </a>
            </div>
          </div>
          <div className="col-lg-6 col-md-10 col-sm-11">
            <div className="pt-9 pt-lg-0 pr-xs-10 pr-sm-5 pr-md-25 pr-lg-0 pl-xl-16">
              <p
                className="font-size-5 mb-7 pr-xs-15 pr-sm-10 pr-xl-15"
                data-aos="fade-up"
                data-aos-duration={900}
              >
              The problem with many online D.I.Y videos is that many processes methods acquire a serious number of intricate instructions, often warranting additional content and footnotes. Bombarding the viewer with all this information can be both confusing and overwhelming. 
              </p>
              <p
                className="font-size-5 mb-0 pr-xs-15 pr-sm-10 pr-xl-15"
                data-aos="fade-up"
                data-aos-duration={900}
              >With DIGMA you can turn your videos into a more hands on and interactive process, helping you to share important information in an incredibly coherent, accessible and structured way. Covering all the bases, DIGMA is the perfect tool for creating a practical and rewarding experience for both you and your audience.               
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default CTA;
