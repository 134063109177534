import React from "react";
import {Link} from "gatsby";
import { Helmet } from "react-helmet";
import PageWrapper from "../../../components/PageWrapper";
import Hero from "../../../sections/diy/Hero";
import Main from "../../../sections/diy/Main";
import Features from "../../../sections/common/Features";
import VideoDemo from "../../../sections/common/VideoDemo";
import Wave from "../../../sections/common/Wave";
import CTA from "../../../sections/CTA/CTA";
import SEO from '../../../components/SEO';
import metaImg from '../../../assets/image/metaimg/diy.jpg';
const PricingPage = () => {
  return (
    <>
      <PageWrapper        
        themeConfig={{
          headerClassName: "site-header--menu-left",
          headerFluid: false,

          footerStyle: "digma",
        }}>
        <SEO
          title="Do It Yourself Interactive Videos"
          description="Following D.I.Y steps couldn't be easier. Inform your customers with timely content to provide the guidence needed."
          image={metaImg}
        /> 
        <Helmet>
        <link
          href={'https://cdn.digma.io/dist/digma.min.2.0.0.css?v=diy'}
          rel="stylesheet"
          />
          <script async src={'https://cdn.digma.io/dist/digma.min.2.0.0.js?v=diy'}></script>
        </Helmet>                                
         <div className="container pt-20 pt-md-16 pt-lg-20 ">
          <nav className="breadcrumb" aria-label="Breadcrumb">
            <ol className="breadcrumb__list">
              <li className="breadcrumb__item">
                <Link className="breadcrumb__link" to="/">Home</Link>
                <span className="breadcrumb__separator" aria-hidden="true"> / </span>
              </li>
              <li className="breadcrumb__item">
                <Link className="breadcrumb__link" to="/industries">Industries</Link>
                <span className="breadcrumb__separator" aria-hidden="true"> / </span>
              </li>
              <li className="breadcrumb__item">
                <Link className="breadcrumb__link" to="/industries/instructional">Instructional</Link>
                <span className="breadcrumb__separator" aria-hidden="true"> / </span>
              </li> 
              <li className="breadcrumb__item">
                <Link className="breadcrumb__link" to="/industries/instructional/diy">D.I.Y</Link>
              </li>                            
              </ol>
           </nav>
        </div>           
        <Hero />
        <Wave pos="top" color="#F7F9FC" />
        <VideoDemo className="grey" name="How to re-skin a baseball" projectId="6C3FE338-79A2-4CDD-8E11-2D779D7D0A0D" />        
        <Wave pos="bottom" color="#fff" background="#F7F9FC" /> 
        <Main />
        <Features className="grey" />
        <CTA className="bg-images" />
      </PageWrapper>
    </>
  );
};
export default PricingPage;
